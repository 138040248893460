import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import fr from './translations/fr.json';

const langEn = 'en';
const langFr = 'fr';

const supportedLanguages = [langEn, langFr];

const configureI18n = () => {
  i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      supportedLngs: supportedLanguages,
      fallbackLng: langEn,
      defaultLanguage: langEn,
      resources: {
        en: {
          translation: en,
        },
        fr: {
          translation: fr,
        },
      },
      react: {
        useSuspense: false,
      },
      interpolation: {
        skipOnVariables: false,
      },
      detection: {
        order: ['querystring'],
        lookupQuerystring: 'lang',
      },
    });
};

export default configureI18n;
