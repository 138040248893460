import {
  Typography,
  Stack,
  Container,
} from '@mui/material';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { decode } from 'base-64';
import { base64url, compactDecrypt } from 'jose';
import LoadingSpinner from '../components/LoadingSpinner';
import PageHeader from '../components/PageHeader';
import PatientCard from '../components/PatientCard';
import networkConfig from '../config/networkConfig';
import { PSContext, SHLContext } from '../Contexts';
import { safeFetch } from '../utils/dataUtil';
import { ErrorTypes, RoutingPaths } from '../constants';
import { VSError } from '../model/error';
import PageLayout from './PageLayout';
import PageFooter from '../components/PageFooter';
import { formatDateAsParts } from '../utils/dateUtil';

/**
 * Layout for Patient Summary landing and section pages. Fetches and provies PS data.
 */
const PSLayout = () => {
  const { files, encryptionKey } = useContext(SHLContext);
  const [translate, i18n] = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [ps, setPS] = useState(null);

  const { showBoundary } = useErrorBoundary();

  const { shl } = useParams();

  // Multiplying the expiry by 1000 because it is in milliseconds and we need seconds to create a new date accurately
  const expiryDate = new Date(JSON.parse(decode(shl)).exp * 1000);
  const [day, month, year, hour, minute] = formatDateAsParts(expiryDate, i18n.language, true);
  const expiry = i18n.language === 'en'
    ? `${month} ${day}, ${year} ${translate('at')} ${hour}:${minute}`
    : `${day} ${month} ${year} ${translate('at')} ${hour}:${minute}`;

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      files.forEach(async (file) => {
        if (file.contentType === 'application/fhir+json') {
          try {
            const config = {
              method: 'GET',
              headers: {
                'X-VSF-API-TOKEN': networkConfig.apiKey,
                'Accept-Language': i18n.language,
              },
              signal: abortController.signal,
            };

            const response = await safeFetch(
              file.location,
              config,
              showBoundary,
            );
            const responseJSON = await response?.json();

            if (responseJSON) {
              const { plaintext } = await compactDecrypt(
                responseJSON.data,
                base64url.decode(encryptionKey),
              );
              const json = JSON.parse(new TextDecoder().decode(plaintext));
              if (json) {
                setInterval(() => {
                  if (new Date() >= expiryDate) {
                    showBoundary(new VSError(ErrorTypes.EXPIRED, '', '', 'FHIR_EXP', 'FHIR'));
                  }
                }, 5000);
                setPS(json);
              }
            }
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  const expiryFooter = (
    <PageFooter>
      <Typography
        variant="body_xs"
        textAlign="center"
        sx={{ color: 'text.secondary' }}
      >
        {`${translate('patientGenerated')} ${expiry}`}
      </Typography>

    </PageFooter>
  );

  return (
    <PSContext.Provider value={useMemo(() => ({ ps, shl }), [ps, shl])}>
      <PageLayout header={<PageHeader homeURL={`/${RoutingPaths.VIEWER}/${shl}?lang=${i18n.language}`} ps={ps} />} footer={expiryFooter}>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {(isLoading || !ps) && <LoadingSpinner />}
          {!isLoading && ps && (
            <Stack
              direction="column"
              alignItems="flex-start"
              padding={4}
              gap={2}
              sx={{ flexGrow: 1 }}
            >
              <PatientCard />
              <Outlet />
            </Stack>
          )}
        </Container>
      </PageLayout>
    </PSContext.Provider>
  );
};

export default PSLayout;
