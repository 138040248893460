import { useMemo, useState } from 'react';
import { decode } from 'base-64';
import { Outlet, useParams } from 'react-router-dom';
import { SHLContext } from '../Contexts';
import PINEntryPage from '../pages/PINEntryPage';

/**
 * Users must complete PIN authentication flow to see children of this route.
 */
const ProtectedRoute = () => {
  const [files, setFiles] = useState([]);
  const [encryptionKey, setEncryptionKey] = useState('');

  // Look at SHL payload to see if PIN is required
  const { shl } = useParams();
  const parsedJSON = JSON.parse(decode(shl));
  const requiresPIN = parsedJSON.flag.includes('P');

  // If PIN required, then don't show PS home page until required secure files are obtained
  const hasAuthorization = !requiresPIN || (files?.length && encryptionKey);

  return (
    <SHLContext.Provider
      value={useMemo(() => (
        {
          files, setFiles, encryptionKey, setEncryptionKey,
        }
      ), [files, encryptionKey])}
    >
      {hasAuthorization ? <Outlet /> : <PINEntryPage shl={parsedJSON} />}
    </SHLContext.Provider>
  );
};

export default ProtectedRoute;
