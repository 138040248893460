/**
 * Types of errors we can display on the error page. All errors thrown in the app must use one of these types.
 * Make sure values have matching text using i18n `errorMessages.<errorType>` key.
 */
const ErrorTypes = {
  /** Patient summary is locked or expired */
  EXPIRED: 'expired',
  /** Patient summary is still being generated */
  GENERATING: 'generating',
  /** Patient summary could not be found */
  NOT_FOUND: 'notFound',
  /** Invalid URL, 404 error */
  PAGE_NOT_FOUND: 'pageNotFound',
  /** Default type */
  UNKNOWN: 'unknown',
};

export default ErrorTypes;
