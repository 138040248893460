import { ExpandCircleDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import displayConfig from '../../config/displayConfig';
import appConfig from '../../config/appConfig';
import BackButton from '../BackButton';
import { RoutingPaths } from '../../constants';
import { PSContext } from '../../Contexts';

const SectionSelect = ({ sectionID }) => {
  const [translate, i18n] = useTranslation();
  const navigate = useNavigate();

  const { shl } = useContext(PSContext);

  // Get supported sections from the display config
  const sectionConfigs = displayConfig[appConfig.spec].sections;

  // Set display value for the dropdown
  const renderValue = (value) => (
    <Stack gap={1}>
      <Typography variant="h_md" sx={{ textWrap: 'wrap' }}>
        {translate(`sections.${value}.title`)}
      </Typography>
      <Typography variant="body_xs" color="text.secondary" sx={{ textWrap: 'wrap' }}>
        {translate(`sections.${value}.subtitle`)}
      </Typography>
    </Stack>
  );

  const changeSection = useCallback(
    (e) => {
      const section = e.target.value;
      // If user has changed the section, then navigate to the new page
      if (sectionID !== section) {
        navigate(`../${section}?lang=${i18n.language}`, { replace: true });
      }
    },
    [sectionID, navigate],
  );

  return (
    <FormControl id="section-select">
      <BackButton homeURL={`/${RoutingPaths.VIEWER}/${shl}?lang=${i18n.language}`} />
      <Select
        value={sectionID}
        onChange={changeSection}
        IconComponent={ExpandCircleDown}
        renderValue={renderValue}
        sx={{
          p: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            p: 0,
          },
          '& .MuiSelect-icon': {
            color: 'text.primary',
          },
        }}
      >
        {sectionConfigs.map((sectionConfig) => (
          <MenuItem key={sectionConfig.id} value={sectionConfig.id}>
            {translate(sectionConfig.titleLabel)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SectionSelect;
