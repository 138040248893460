/* eslint { import/prefer-default-export: 0 } */

import '@fontsource/lato';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';

import { createTheme } from '@mui/material/styles';

const themeGlobals = createTheme(
  {
    // Only add colour codes used by MUI components: https://mui.com/system/palette/#color
    palette: {
      primary: {
        main: '#006055',
        light: '#00605599',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#3B3B3B',
        secondary: '#595959',
      },
      success: {
        main: '#2E7D32',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#D32F2F',
        light: '#D32F2F33',
        dark: '#79242F',
        contrastText: '#3B3B3B',
      },
      warning: {
        main: '#D6A329',
        contrastText: '#FFFFFF',
      },
    },
    spacing: [0, 4, 8, 12, 16, 24, 32, 64],
    typography: {
      fontFamily: 'Lato',
      h_xxs: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '16px',
      },
      h_sm: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '20px',
      },
      h_md: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '26px',
      },
      h_lg: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '34px',
      },
      body_xs: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '12px',
      },
      body_sm: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
      },
      body_md: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
      },
      body_lg: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
  },
);

export const theme = createTheme({
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'pCard' },
          style: {
            height: 'auto',
            backgroundColor: themeGlobals.palette.primary.main,
            color: themeGlobals.palette.primary.contrastText,
            borderRadius: '4px',
            padding: '4px 6px 4px 6px',
            '& .MuiChip-label': {
              padding: 0,
              ...themeGlobals.typography.body_xs,
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& [class*="MuiDialog-paper"]': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: themeGlobals.palette.primary.main,
          '& [class*="MuiTypography-root"]': {
            color: themeGlobals.palette.primary.contrastText,
          },
          '& [class*="MuiIconButton-root"]': {
            color: themeGlobals.palette.primary.contrastText,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiAvatar: {
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            ...themeGlobals.typography.h_md,
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Lato',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: themeGlobals.palette.primary.main,
          color: themeGlobals.palette.primary.contrastText,
          fontSize: 16,
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 12px 8px 12px',
          '&.Mui-disabled': {
            backgroundColor: themeGlobals.palette.background.tertiary,
            color: themeGlobals.palette.text.label,
          },
          '&:hover': {
            backgroundColor: `${themeGlobals.palette.primary.main}90`,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        // Recommended to improve performance: https://mui.com/material-ui/react-text-field/#performance
        disableInjectingGlobalStyles: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          strokeLinecap: 'round',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-error': {
            backgroundColor: '#FFE4E4',
          },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#C5C5C5',
          },
        },
      },
    },
  },
}, themeGlobals);
