import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RoutingPaths from '../constants/routingPaths';
import LandingPage from '../pages/LandingPage';
import PSLayout from '../layouts/PSLayout';
import SectionPage from '../pages/SectionPage';
import ErrorPage from '../pages/ErrorPage';
import { GenericError } from '../model/error';
import { ErrorTypes } from '../constants';

const Routing = () => (
  <Routes>
    <Route path={`${RoutingPaths.VIEWER}/${RoutingPaths.SHL_PARAM}`} element={<ProtectedRoute />}>
      <Route element={<PSLayout />}>
        <Route index element={<LandingPage />} />
        <Route path={RoutingPaths.SECTION_PARAM} element={<SectionPage />} />
      </Route>
    </Route>
    <Route path="*" element={<ErrorPage error={new GenericError(ErrorTypes.PAGE_NOT_FOUND)} />} />
  </Routes>
);

export default Routing;
