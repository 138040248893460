/**
 * @fileoverview Utilities for fetching sections and entries from withint the Patient Summary bundle.
 */

import { getByDisplayKey } from './displayKeyUtil';
import SectionEntryRow from '../components/EntryRow';
import SectionEmptyRow from '../components/EmptyRow';
import { sortByDateFieldDescending } from './dateUtil';

/**
 * Given a patient summary and config, return the section whose coding matches the coding in the config
 * @param {string} ps Patient Summary.
 * @param {Object} section Section of the summary we're looking at.
 * @returns {Object} A section of the patient summary
*/
export const getSectionByConfig = (ps, sectionConfig) => (
  ps?.entry[0]?.resource?.section?.find(
    (section) => getByDisplayKey(section, 'code.coding.0.code') === sectionConfig.code,
  )
);

/**
 * Searches for entries within a section and returns a list of elements to display
 * @param {string} ps Patient Summary.
 * @param {Object} section Section of the summary we're looking at.
 * @param {Object} sectionConfig Config for the section.
 * @returns {React.ReactElement} A list of EntryRow elements to display on SectionPage
*/
export const getEntries = (ps, section, sectionConfig) => {
  const entryReferences = section?.entry?.map((entry) => entry?.reference) || [];

  // Get the entry object for each reference
  const entries = entryReferences
    .map((reference) => ps?.entry?.find((psSection) => psSection?.fullUrl === reference))
    .filter((entry) => {
      const filterCondition = sectionConfig.filterKey ? sectionConfig.filters.includes(getByDisplayKey(entry, sectionConfig.filterKey)) : true;
      const resourceTypeCondition = sectionConfig.resourceType ? sectionConfig.resourceType.includes(entry?.resource?.resourceType) : true;
      return filterCondition && resourceTypeCondition;
    });

  // Breakpoint to check if this section is empty
  // Mandatory PS sections (for our case Medication Summary) will always exist in the bundle with one entry
  if (!entries[0]
    || entries[0]?.resource?.medicationCodeableConcept?.coding[0]?.code === 'no-medication-info'
  ) {
    return <SectionEmptyRow />;
  }

  const sortedEntries = sortByDateFieldDescending(entries, sectionConfig.sortKey, sectionConfig.fallbackSortKey);

  return sortedEntries.map((entry) => (
    <SectionEntryRow key={entry.fullUrl} entry={entry} entryConfig={sectionConfig.entry} />
  ));
};
