import { useState } from 'react';
import {
  AppBar,
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  useScrollTrigger,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as MyHealthLogo } from '../img/MyHealth-full-white.svg';
import appConfig from '../config/appConfig';
import DisclaimerModal from './DisclaimerModal';
import displayConfig from '../config/displayConfig';
import { getByDisplayKey } from '../utils/displayKeyUtil';

const PageHeader = ({ homeURL, ps }) => {
  const [translate, i18n] = useTranslation();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  // Get composition resource from the bundle
  const compositionConfig = displayConfig[appConfig.spec].composition;
  const compositionResource = ps?.entry.find((e) => e.resource.resourceType === compositionConfig.resourceType) || {};

  const date = getByDisplayKey(compositionResource, compositionConfig.dateKey, '', i18n.language);

  return (
    <>
      <DisclaimerModal
        open={disclaimerOpen}
        closeModal={() => setDisclaimerOpen(false)}
      />
      <AppBar
        position="sticky"
        elevation={trigger ? 4 : 0}
        sx={{ alignItems: 'center' }}
      >
        <Box
          maxWidth="sm"
          sx={{
            textAlign: 'left',
            width: 'stretch',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: 1,
          }}
        >
          <Link
            to={homeURL}
            style={{
              textDecoration: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
            }}
          >
            {appConfig.displayHeaderHomeIcon && <HomeIcon fontSize="large" htmlColor="white" />}
            <MyHealthLogo width="115" height="46" />
          </Link>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1, borderColor: 'white' }}
          />
          <Stack
            sx={{
              width: '100%',
              paddingY: 1,
            }}
          >
            <Typography variant="h_sm">{translate('psTitle')}</Typography>
            {appConfig.displayHeaderDate && date && (
              <Typography
                variant="body_xs"
                sx={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                {`${translate('asOfSubtitle')} ${date}`}
              </Typography>
            )}
          </Stack>
          <IconButton onClick={() => setDisclaimerOpen(true)}>
            <InfoOutlinedIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </AppBar>
    </>
  );
};

export default PageHeader;
