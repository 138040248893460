import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = ({ homeURL }) => {
  const { palette } = useTheme();

  const [translate] = useTranslation();

  return (
    <Link
      to={homeURL}
      style={{
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: palette.text.secondary,
      }}
    >
      <ArrowBackIosIcon fontSize="12px" htmlColor="inherit" style={{ verticalAlign: 'middle' }} />
      <Typography variant="body_xs" component="span">
        {translate('back')}
      </Typography>
    </Link>
  );
};

export default BackButton;
