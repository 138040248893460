import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  IconButton,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';

const DisclaimerModal = ({ open, closeModal }) => {
  const [translate] = useTranslation('translation', {
    keyPrefix: 'disclaimer',
  });
  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle sx={{ paddingX: 4, paddingY: 3 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <IconButton sx={{ padding: 0 }}>
            <InfoOutlinedIcon />
          </IconButton>
          <Typography>{translate('title')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Stack gap={1} sx={{ marginX: 4, marginY: 4, padding: 0 }}>
          <Typography variant="body_sm">{translate('content-a')}</Typography>
          <Typography variant="body_sm">{translate('content-b')}</Typography>
          <a href={translate('privacyURL')} target="_blank" rel="noreferrer">
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                gap: 1,
                px: 2,
                py: 3,
                width: '95%',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body_sm">{translate('privacy')}</Typography>
              <OpenInNew fontSize="small" />
            </Stack>
          </a>
          <Divider />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          marginX: 4, marginTop: 0, marginBottom: 4, padding: 0,
        }}
      >
        <Button onClick={closeModal}>
          <Typography variant="body_sm">{translate('accept')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerModal;
