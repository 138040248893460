import { useState } from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';

const JSONCard = ({ ps }) => {
  const [viewSource, setViewSource] = useState(false);
  const [translate] = useTranslation();

  return (
    <Card
      key="viewSource"
      sx={{ width: '100%', borderRadius: 2, boxShadow: 0 }}
    >
      <CardActionArea onClick={() => setViewSource(!viewSource)}>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body_lg">{translate('viewSourceTitle')}</Typography>
            <ChevronRight fontSize="large" />
          </Stack>
        </CardContent>
      </CardActionArea>
      <Collapse in={viewSource}>
        <ReactJson
          name={false}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          src={ps ? JSON.parse(JSON.stringify(ps)) : null}
          indentWidth={2}
          style={{
            fontSize: '0.8em',
            maxHeight: '600px',
            padding: '8px',
            overflowY: 'auto',
          }}
        />
      </Collapse>
    </Card>
  );
};

export default JSONCard;
