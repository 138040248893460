import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VeroSourceLogo } from '../img/verosource-logo-grey.svg';

const PageFooter = ({ children }) => {
  const [translate] = useTranslation();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        paddingX: 2,
        paddingBottom: 2,
        gap: 2,
      }}
    >
      {children}
      <Typography variant="h_xxs" textAlign="center">{translate('closeBrowser')}</Typography>
      <VeroSourceLogo width="110px" height="33px" />
    </Stack>
  );
};

export default PageFooter;
