import { getByDisplayKey } from '../utils/displayKeyUtil';

/**
 * Properties ending with "Label" refer to keys in the translation file.
 * Properties ending with "Key" refer to display keys to use with the `getByDisplayKey` function.
 */
const displayConfig = {
  psca: {
    composition: {
      resourceType: 'Composition',
      dateKey: 'DATE_AS_TEXT:::resource.date',
    },
    patient: {
      resourceType: 'Patient',
      firstnameKey: 'resource.name.0.given.0',
      lastnameKey: 'resource.name.0.family',
      dobLabel: 'patientCard.dateOfBirth',
      dobKey: 'DATE_AS_TEXT:::resource.birthDate',
      hcnLabel: 'patientCard.identifier',
      hcnKey: 'MATCHED_VALUE:::resource.identifier:::type.coding.0.code:::JHN:::value',
    },
    patientSection: {
      resourceType: 'Patient',
      fields: [
        {
          label: 'sections.PATIENT.fields.identifiers',
          value: 'MATCHED_VALUE:::resource.identifier:::type.coding.0.code:::JHN:::value',
        },
        {
          label: 'sections.PATIENT.fields.name',
          value: 'COMPOUND_VALUE:::resource.name.0.given.0:::resource.name.0.family',
        },
        {
          label: 'sections.PATIENT.fields.gender',
          value: 'FIRST_LETTER_UPPERCASE:::resource.gender',
        },
        {
          label: 'sections.PATIENT.fields.dateOfBirth',
          value: 'DATE_AS_TEXT:::resource.birthDate',
        },
      ],
    },
    sections: [
      {
        id: 'MS',
        code: '10160-0',
        titleLabel: 'sections.MS.title',
        subtitleLabel: 'sections.MS.subtitle',
        sortKey: 'resource.authoredOn',
        fallbackSortKey: 'resource.medicationCodeableConcept.coding.0.display',
        entry: {
          titleKey: 'resource.medicationCodeableConcept.coding.0.display',
          subtitleKey: '',
          dateKey: 'resource.authoredOn',
          modalLabel: 'sections.MS.details',
          fields: [
            {
              label: 'sections.MS.fields.medicationName',
              value: 'resource.medicationCodeableConcept.coding.0.display',
            },
            {
              label: 'sections.MS.fields.prescribedDate',
              value: 'DATE_AS_TEXT:::resource.authoredOn',
            },
            {
              label: 'sections.MS.fields.directions',
              value: 'resource.dosageInstruction.0.text',
            },
            {
              vanityLabel: 'sections.MS.fields.status',
              label: 'sections.MS.fields.status.title',
              value: 'resource.status',
            },
            {
              label: 'sections.MS.fields.prescriber',
              value: 'resource.requester.display',
            },
          ],
        },
      },
      {
        id: 'IM',
        code: '11369-6',
        titleLabel: 'sections.IM.title',
        subtitleLabel: 'sections.IM.subtitle',
        sortKey: 'resource.occurrenceDateTime',
        fallbackSortKey: '',
        entry: {
          titleKey: 'MATCHED_VALUE:::resource.vaccineCode.coding:::system:::https://cvc.canimmunize.ca/v3/ValueSet/Generic:::display',
          subtitleKey: 'JOINED_LIST:::resource.protocolApplied.0.targetDisease:::coding.0.display',
          dateKey: 'resource.occurrenceDateTime',
          modalLabel: 'sections.IM.details',
          fields: [
            {
              label: 'sections.IM.fields.immunizationAgent',
              value: 'MATCHED_VALUE:::resource.vaccineCode.coding:::system:::https://cvc.canimmunize.ca/v3/ValueSet/Generic:::display',
            },
            {
              label: 'sections.IM.fields.receivedDate',
              value: 'DATE_AS_TEXT:::resource.occurrenceDateTime',
            },
            {
              label: 'sections.IM.fields.tradeName',
              value: 'MATCHED_VALUE:::resource.vaccineCode.coding:::system:::https://cvc.canimmunize.ca/v3/ValueSet/TradeName:::display',
            },
            {
              label: 'sections.IM.fields.targetDiseases',
              value: 'JOINED_LIST:::resource.protocolApplied.0.targetDisease:::coding.0.display',
            },
            {
              label: 'sections.IM.fields.status',
              value: 'resource.status',
            },
          ],
        },
      },
      {
        id: 'LR',
        code: '30954-2',
        resourceType: ['DiagnosticReport'],
        titleLabel: 'sections.LR.title',
        subtitleLabel: 'sections.LR.subtitle',
        sortKey: 'resource.effectiveDateTime',
        fallbackSortKey: 'resource.code.coding.0.display',
        filterKey: 'resource.category.1.coding.0.code',
        filters: ['BLB', 'CH', 'CP', 'GE', 'HM', 'MB', 'SP', 'SR', 'COA', 'CHA'],
        entry: {
          titleKey: 'resource.code.coding.0.display',
          subtitleKey: '',
          dateKey: 'resource.effectiveDateTime',
          modalLabel: 'sections.LR.details',
          showDisclaimer: (entry) => getByDisplayKey(entry, 'resource.text.status') === 'additional',
          disclaimerLabel: 'sections.LR.pdfDisclaimer',
          showPatientInformation: true,
          fields: [
            {
              label: 'sections.LR.fields.labTestName',
              value: 'resource.code.coding.0.display',
            },
            {
              label: 'sections.LR.fields.collectionDate',
              value: 'DATE_TIME_AS_TEXT:::resource.effectiveDateTime',
            },
            {
              label: 'sections.LR.fields.facility',
              value: [
                'FIND_BY_IDENTIFIER_NESTED',
                'resource.contained:::resourceType:::ServiceRequest',
                'locationReference:::identifier.system:::https://fhir.infoway-inforoute.ca/NamingSystem/ca-nb-facility-id',
                'display',
              ].join(':::'),
            },
            {
              label: 'sections.LR.fields.specimenNumber',
              value: 'resource.contained.1.identifier.0.value',
            },
            {
              label: 'sections.LR.fields.orderingProvider',
              value: 'resource.contained.0.requester.display',
            },
            {
              label: 'sections.LR.fields.labCategory',
              value: 'resource.category.1.coding.0.display',
            },
            {
              label: 'sections.LR.fields.notes',
              value: 'resource.conclusion',
              note: true,
              textTransform: () => ({ fontSize: '9px', fontFamily: 'courier', fontWeight: 700 }),
            },
            {
              label: 'sections.LR.fields.report',
              value: 'resource.text.div',
              note: true,
              textTransform: () => ({
                fontSize: '9px',
                fontFamily: 'courier',
                fontWeight: 700,
                lineHeight: '9px',
              }),
            },
          ],
          nestedFields: [
            {
              idKey: 'resource.result',
              fields: [
                {
                  label: 'sections.LR.fields.labTestName',
                  value: 'resource.code.coding.0.display',
                },
                {
                  vanityLabel: 'sections.LR.fields.statusMap',
                  label: 'sections.LR.fields.status',
                  value: 'resource.status',
                },
                {
                  label: 'sections.LR.fields.result',
                  value: 'COMPOUND_VALUE:::resource.valueQuantity.value:::resource.valueQuantity.unit',
                },
                {
                  label: 'sections.LR.fields.result',
                  value: 'resource.valueString',
                },
                {
                  label: 'sections.LR.fields.referenceRange',
                  value: 'resource.referenceRange.0.text',
                },
                {
                  label: '',
                  vanityLabel: 'sections.LR.fields.interpretation',
                  textTransform: (value) => (['A', 'H', 'L', 'AA', 'HH', 'LL'].includes(value) && { fontWeight: 700 }) || {},
                  value: 'resource.interpretation.0.coding.0.code',
                },
                {
                  label: 'sections.LR.fields.notes',
                  value: 'resource.note.0.text',
                  note: true,
                  textTransform: () => ({ fontSize: '9px', fontFamily: 'courier', fontWeight: 700 }),
                },
              ],
            },
          ],
        },
      },
      {
        id: 'IR',
        code: '30954-2',
        titleLabel: 'sections.IR.title',
        subtitleLabel: 'sections.IR.subtitle',
        sortKey: 'resource.effectiveDateTime',
        fallbackSortKey: 'resource.code.coding.0.display',
        filterKey: 'resource.category.1.coding.0.code',
        filters: ['CT', 'NMR', 'XRA', 'ULS', 'NME'],
        entry: {
          titleKey: 'resource.code.coding.0.display',
          subtitleKey: 'resource.category.1.coding.0.display',
          dateKey: 'resource.effectiveDateTime',
          modalLabel: 'sections.IR.details',
          showDisclaimer: (entry) => getByDisplayKey(entry, 'resource.text.status') === 'additional',
          disclaimerLabel: 'sections.IR.pdfDisclaimer',
          showPatientInformation: true,
          fields: [
            {
              label: 'sections.IR.fields.imagingReportName',
              value: 'resource.code.coding.0.display',
            },
            {
              label: 'sections.IR.fields.imagingDate',
              value: 'DATE_TIME_AS_TEXT:::resource.effectiveDateTime',
            },
            {
              label: 'sections.IR.fields.imagingType',
              value: 'resource.category.1.coding.0.display',
            },
            {
              label: 'sections.IR.fields.orderingProvider',
              value: 'resource.contained.0.requester.display',
            },
            {
              label: 'sections.IR.fields.examNumber',
              value: 'FIRST_ELEMENT:::resource.identifier.0.value',
            },
            {
              label: 'sections.IR.fields.notes',
              value: 'resource.conclusion',
              note: true,
              textTransform: () => ({ fontSize: '9px', fontFamily: 'courier', fontWeight: 700 }),
            },
            {
              label: 'sections.IR.fields.report',
              value: 'resource.text.div',
              note: true,
              textTransform: () => ({
                fontSize: '9px',
                fontFamily: 'courier',
                fontWeight: 700,
                lineHeight: '9px',
              }),
            },
          ],
        },
      },
    ],
    noInformationResourceSystems: [
      'http://hl7.org/fhir/uv/ips/CodeSystem/absent-unknown-uv-ips',
    ],
  },
};

export default displayConfig;
