import {
  Avatar,
  Typography,
  Chip,
  Stack,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PSContext } from '../Contexts';
import displayConfig from '../config/displayConfig';
import appConfig from '../config/appConfig';
import { getByDisplayKey } from '../utils/displayKeyUtil';

const PatientCard = () => {
  const [translate, i18n] = useTranslation();

  // Get patient resource from the bundle
  const patientConfig = displayConfig[appConfig.spec].patient;
  const { ps } = useContext(PSContext);
  const patientResource = ps.entry.find((e) => e.resource.resourceType === patientConfig.resourceType);
  const firstname = getByDisplayKey(patientResource, patientConfig.firstnameKey);
  const lastname = getByDisplayKey(patientResource, patientConfig.lastnameKey);
  const initials = firstname.toUpperCase()[0].concat(lastname.toUpperCase()[0]);

  const dob = getByDisplayKey(patientResource, patientConfig.dobKey, '', i18n.language);
  const hcn = getByDisplayKey(patientResource, patientConfig.hcnKey).replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');

  return (
    <Stack
      sx={{
        width: '100%',
        gap: 2,
      }}
    >
      <Stack direction="row" sx={{ gap: 2 }}>
        <Avatar
          variant="rounded"
          sx={{
            p: 2,
            color: 'primary.contrastText',
            bgcolor: 'primary.main',
          }}
        >
          <Typography variant="h_md">{initials}</Typography>
        </Avatar>
        <Stack sx={{ gap: 2 }}>
          <Typography variant="h_md">{`${firstname} ${lastname}`}</Typography>
          <Stack direction="row" sx={{ gap: 2 }}>
            <Chip
              variant="pCard"
              key={patientConfig.dobLabel}
              label={`${translate(patientConfig.dobLabel)}: ${dob}`}
            />
            <Chip
              variant="pCard"
              key={patientConfig.hcnLabel}
              label={`${translate(patientConfig.hcnLabel)}: NB ${hcn}`}
            />
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default PatientCard;
