/**
 * Types of error severities. An error's severity will affect how it is shown on the error page.
 * All errors thrown in the app must use one of these types.
 */
const ErrorSeverities = {
  INFO: 'info',
  WARNING: 'warning',
  CRITICAL: 'critical',
  GENERATING: 'generating',
};

export default ErrorSeverities;
